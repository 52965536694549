import React, { useState, useEffect } from 'react'
import {Button, Row, Col, ListGroup, Image, Card, ListGroupItem} from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails, payOrder, deliverOrder } from '../actions/orderActions'
// import {PayPalButton} from "react-paypal-button-v2";
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from "../constants/orderConstants";

function OrderScreen() {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const dispatch = useDispatch();
    
    const [sdkReady, setSdkReady] = useState(false);
    
    const orderDetails = useSelector(state => state.orderDetails)
    const { order, error, loading } = orderDetails
    
    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay
    
    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    if (!loading && !error) {
        order.itemsPrice = order.orderItems.reduce(
            (acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    }
    
    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        }
        
        if (!order || successPay || order._id !== Number(id) || successDeliver) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch({ type: ORDER_DELIVER_RESET })
            dispatch(getOrderDetails(id))
        } else if (!order.isPaid) {
            setSdkReady(true);
        }
    }, [dispatch, order, id, successPay, successDeliver, navigate, userInfo])
    
    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(id, paymentResult))
    }
    
    const deliverHandler = () => {
        dispatch(deliverOrder(order))
    }
    
    return loading ? (
        <Loader/>
    ) : error ? (
        <Message variant='danger'>{ error }</Message>
    ) : (
        <div>
            <h1>Order: { order._id }</h1>
            <Row>
                <Col md={ 8 }>
                    <ListGroup variant="flush">
                        <ListGroupItem>
                            <h2>Shipping</h2>
                            <p><strong>Name: </strong>{ order.user.name }</p>
                            <p><strong>Email: </strong><a href={ `mailto:${ order.user.email }` }>
                                { order.user.email }</a></p>
                            <p>
                                <strong>Shipping: </strong>
                                { order.shippingAddress.address }, { order.shippingAddress.city }
                                { ' ' }
                                { order.shippingAddress.postalCode },
                                { ' ' }
                                { order.shippingAddress.country }
                            </p>
                            { order.isDelivered ? (
                                <Message variant='success'>Delivered on {
                                    order.deliveredAt.replace('T', ' ').split('.')[0]
                                }</Message>
                            ) : (
                                <Message variant='warning'>Not Delivered</Message>
                            ) }
                        </ListGroupItem>
                        <ListGroupItem>
                            <h2>Payment Method</h2>
                            <p>
                                <strong>Method: </strong>
                                { order.paymentMethod }
                            </p>
                            { order.isPaid ? (
                                <Message variant='success'>Paid on {
                                    order.paidAt.replace('T', ' ').split('Z')[0]
                                }</Message>
                            ) : (
                                <Message variant='warning'>Not Paid</Message>
                            ) }
                        </ListGroupItem>
                        
                        <ListGroupItem>
                            <h2>Order Items</h2>
                            { order.orderItems.length === 0 ? <Message variant={ 'info' }>
                                Order is empty
                            </Message> : (
                                <ListGroup variant="flush">
                                    { order.orderItems.map((item, index) => (
                                        <ListGroupItem key={ index }>
                                            <Row>
                                                <Col md={ 2 }>
                                                    <Image
                                                        src={ `/public_html/static${item.image}` } alt={ item.name }
                                                        fluid rounded/>
                                                </Col>
                                                <Col>
                                                    <Link to={ `/product/${ item.product }` }>
                                                        { item.name }</Link>
                                                </Col>
                                                <Col md={ 4 }>
                                                    { item.qty } x ${ item.price } =
                                                    ${ (item.qty * item.price).toFixed(2) }
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )) }
                                </ListGroup>
                            ) }
                        </ListGroupItem>
                    </ListGroup>
                </Col>
                
                <Col md={ 4 }>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroupItem>
                                <h2>Order Summary</h2>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Items: </Col>
                                    <Col>${ order.itemsPrice }</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Shipping: </Col>
                                    <Col>${ order.shippingPrice }</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Tax: </Col>
                                    <Col>${ order.taxPrice }</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Total: </Col>
                                    <Col>${ order.totalPrice }</Col>
                                </Row>
                            </ListGroupItem>
                            { !order.isPaid && (
                                <ListGroupItem className="text-center">
                                    { loadingPay && <Loader/> }
    
                                    <Button type="button"
                                            onClick={ successPaymentHandler }>
                                        Pay Button
                                    </Button>
                                </ListGroupItem>
                            ) }
                        </ListGroup>
                        { loadingDeliver && <Loader/> }
                        { userInfo && userInfo.isadmin && order.isPaid && !order.isDelivered && (
                            <ListGroupItem className="text-center">
                                <Button type="button"
                                        className="btn btn-block"
                                        onClick={ deliverHandler }>
                                    Mark As Delivered
                                </Button>
                            </ListGroupItem>
                        ) }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default OrderScreen;
