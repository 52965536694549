import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, FormGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { getUserDetails, updateUser } from '../actions/userActions';
import { USER_UPDATE_RESET } from '../constants/userConstants';

function UserEditScreen() {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isadmin, setIsAdmin] = useState(false)
    const dispatch = useDispatch()
    
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails
    
    const userUpdate = useSelector(state => state.userUpdate)
    const {
        error: errorUpdate, loading: loadingUpdate, success: successUpdate
    } = userUpdate
    
    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: USER_UPDATE_RESET })
            navigate('/adminuser/userlist')
        } else {
            if (!user.name || user._id !== Number(id)) {
                dispatch(getUserDetails(id))
            } else {
                setName(user.name)
                setEmail(user.email)
                setIsAdmin(user.isadmin)
            }
        }
    }, [user, id, successUpdate, navigate, dispatch])
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUser({ _id: user._id, name, email, isadmin }))
    }
    
    return (
        <div>
            <Link to={ '/adminuser/userlist' }>
                <Button variant="outline-primary" className="mt-2">
                    Go Back
                </Button>
            </Link>
        
            <FormContainer>
                <h1 className="text-center mb-4">Edit User</h1>
                { loadingUpdate && <Loader/> }
                { errorUpdate && <Message variant={ 'danger' }>{ errorUpdate }</Message> }
                { loading ? <Loader/> : error
                    ? <Message variant={ 'danger' }>{ error }</Message>
                    : (
                        <Form onSubmit={ submitHandler }>
                            <FormGroup controlId="name" className={ 'mb-2' }>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="name" placeholder='Enter name'
                                    value={ name } onChange={ (e) => setName(e.target.value) }/>
                            </FormGroup>
                            <FormGroup controlId="email" className={ 'mb-2' }>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email" placeholder='Enter Email'
                                    value={ email } onChange={ (e) => setEmail(e.target.value) }/>
                            </FormGroup>
                            <FormGroup controlId="isadmin" className={ 'mb-2' }>
                                <Form.Check
                                    type="checkbox" label={ 'Is Admin' }
                                    checked={ isadmin }
                                    onChange={ (e) => setIsAdmin(e.target.checked) }/>
                            </FormGroup>
                        
                            <Button
                                type={ 'submit' } variant="primary"
                                className={ 'mt-3 d-block mx-auto' }>
                                Update
                            </Button>
                        </Form>
                    ) }
            </FormContainer>
        </div>
    );
}

export default UserEditScreen;
