import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import { listProducts, deleteProduct, createProduct } from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

function ProductListScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const productList = useSelector(state => state.productList);
    const { loading, error, products, pages, page } = productList;
    
    const productDelete = useSelector(state => state.productDelete);
    const {
        loading: loadingDelete, error: errorDelete, success: successDelete
    } = productDelete;
    
    const productCreate = useSelector(state => state.productCreate);
    const {
        loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct
    } = productCreate;
    
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    
    let keyword = location.search;
    useEffect(() => {
        dispatch({ type: PRODUCT_CREATE_RESET })
        if (!userInfo.isadmin) {
            navigate('/login')
        }
        if (successCreate) {
            navigate(`/adminuser/product/${ createdProduct._id }/edit`)
        } else {
            dispatch(listProducts(keyword))
        }
    }, [dispatch, navigate, userInfo, successDelete,
        successCreate, createdProduct, keyword])
    
    const deleteHandler = (id) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            dispatch(deleteProduct(id));
        }
    }
    
    const createProductHandler = () => {
        dispatch(createProduct());
    }
    
    return (
        <div>
            <Row className="align-items-center">
                <Col>
                    <h1>Products</h1>
                </Col>
                
                <Col className="text-right">
                    <Button className="my-4" variant="outline-dark" onClick={ createProductHandler }>
                        <i className="fas fa-plus"/>&nbsp;Create Product
                    </Button>
                </Col>
            </Row>
            
            { loadingDelete && <Loader/> }
            { errorDelete && <Message variant='danger'>{ errorDelete }</Message> }
            
            
            { loadingCreate && <Loader/> }
            { errorCreate && <Message variant='danger'>{ errorCreate }</Message> }
            
            { loading
                ? (<Loader/>)
                : error
                    ? (<Message variant='danger'>{ error }</Message>)
                    : (
                        <div>
                            <Table striped bordered hover responsive className='table-sm'>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>NAME</th>
                                    <th>PRICE</th>
                                    <th>CATEGORY</th>
                                    <th>BRAND</th>
                                    <th/>
                                </tr>
                                </thead>
                                
                                <tbody>
                                { products.map(product => (
                                    <tr key={ product._id }>
                                        <td>{ product._id }</td>
                                        <td>{ product.name }</td>
                                        <td>${ product.price }</td>
                                        <td>{ product.category }</td>
                                        <td>{ product.brand }</td>
                                        
                                        <td className="text-center">
                                            <LinkContainer
                                                to={ `/adminuser/product/${ product._id }/edit` }
                                            >
                                                <Button variant='light' className='btn-sm mx-1'>
                                                    <i className='fas fa-edit'/>
                                                </Button>
                                            </LinkContainer>
                                            
                                            <Button variant='danger' className='btn-sm mx-1'
                                                    onClick={ () => deleteHandler(product._id) }>
                                                <i className='fas fa-trash'/>
                                            </Button>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </Table>
                            <Paginate pages={ pages } page={ page } isadmin={ true }/>
                        </div>
                    ) }
        </div>
    );
}

export default ProductListScreen;
