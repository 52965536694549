import React, {Component} from "react";
import axios from 'axios';
import {Container} from "react-bootstrap";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default class App extends Component {
    
    render() {
        return (
            <div>
                <Router>
                    <Header/>
                    <main className="py-2">
                        <Container>
                            <Routes>
                                <Route path='/' element={ <HomeScreen/> }/>
                                <Route path='/login' element={ <LoginScreen/> }/>
                                <Route path='/register' element={ <RegisterScreen/> }/>
                                <Route path='/profile' element={ <ProfileScreen/> }/>
                                <Route path='/shipping' element={ <ShippingScreen/> }/>
                                <Route path='/placeorder' element={ <PlaceOrderScreen/> }/>
                                <Route path='/order/:id' element={ <OrderScreen/> }/>
                                <Route path='/payment' element={ <PaymentScreen/> }/>
                                <Route path='/product/:id' element={ <ProductScreen/> }/>
                                <Route path='/cart'>
                                    <Route path=':productid' element={ <CartScreen/> }/>
                                    <Route path='' element={ <CartScreen/> }/>
                                </Route>
                                <Route path='/adminuser'>
                                    <Route path='userlist' element={ <UserListScreen/> }/>
                                    <Route path='user/:id/edit' element={ <UserEditScreen/> }/>
                                
                                    <Route path='productlist' element={ <ProductListScreen/> }/>
                                    <Route path='product/:id/edit' element={ <ProductEditScreen/> }/>
                                
                                    <Route path='orderlist' element={ <OrderListScreen/> }/>
                                </Route>
                            </Routes>
                        </Container>
                
                    </main>
                    <Footer/>
                </Router>
            </div>
        );
    }
}
