import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from "react-router-dom";
import {Form, Button, Row, Col, FormGroup} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import {login} from "../actions/userActions";

export default function LoginScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const dispatch = useDispatch();
    
    const redirect = location.search ? location.search.split('=')[1] : '/';
    
    const userLogin = useSelector(state => state.userLogin);
    const {error, loading, userInfo} = userLogin;
    
    useEffect(() => {
        if(userInfo) {
            navigate(redirect)
        }
    }, [navigate, userInfo, redirect]);
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };
    
    return (
        <FormContainer>
            <h1>Sign In</h1>
            {error && <Message variant={'danger'}>{error}</Message>}
            {loading && <Loader/>}
            <Form onSubmit={submitHandler}>
                <FormGroup controlId="email" className={'my-4'}>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        type="email" placeholder='Enter Email'
                        value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormGroup>
                <FormGroup controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        type="password" placeholder={'Enter Password'}
                        value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormGroup>
                
                <Button type={'submit'} variant="primary" className={'mt-3'}>
                    Sign In
                </Button>
            </Form>
            
            <Row className={'py-3'}>
                <Col>
                    New Customer?&nbsp;
                    <Link
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}
                        className={"reglink"}
                    >
                        Register
                    </Link>
                </Col>
            </Row>
        </FormContainer>
    );
}
