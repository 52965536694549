import React from 'react';
import {Pagination} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

function Paginate({pages, page, keyword = '', isadmin = false}) {
    
    if (keyword) {
        keyword = keyword.split('?keyword=')[1].split('&')[0]
    }
    
    return (pages > 1 && (
            <Pagination size="sm" className="justify-content-center">
                { [...Array(pages).keys()].map((x) => (
                    <LinkContainer
                        key={ x + 1 }
                        to={ !isadmin ?
                            `/?keyword=${ keyword }&page=${ x + 1 }`
                            :
                            `/adminuser/productlist/?keyword=${ keyword }&page=${ x + 1 }`
                        }
                    >
                        <Pagination.Item active={ x + 1 === page }>
                            { x + 1 }
                        </Pagination.Item>
                    </LinkContainer>
                )) }
            </Pagination>
        )
    );
}

export default Paginate;
