import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Row, Col, Image, ListGroup, Button, Card, Form, ListGroupItem, FormGroup
} from 'react-bootstrap';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProductDetails, createProductReview } from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";

function ProductScreen() {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [qty, setQty] = useState(1);
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')
    
    const productDetails = useSelector(state => state.productDetails);
    const {loading, error, product} = productDetails;
    
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    
    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const {
        loading: loadingProductReview, error: errorProductReview,
        success: successProductReview
    } = productReviewCreate
    
    useEffect(() => {
        if(successProductReview) {
            setRating(0)
            setComment('')
            dispatch({type: PRODUCT_CREATE_REVIEW_RESET})
        }
        dispatch(listProductDetails(id))
    }, [dispatch, id, successProductReview]);
    
    const addToCartHandler = () => {
       navigate(`/cart/${id}?qty=${qty}`);
    }
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(createProductReview(id, {rating, comment}));
    }
    
    return (
        <div>
            <Link to="/" className={'btn btn-light my-3'}>Go Back</Link>
            {loading ? <Loader/>
                : error ? <Message variant={'danger'}>{error}</Message>
                    :(
                        <div>
                            <Row>
                                <Col md={6}>
                                    <Image src={`/public_html/static${product.image}`} alt={product.name} fluid/>
                                </Col>
                                <Col md={3}>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <h3>{product.name}</h3>
                                        </ListGroup.Item>
                            
                                        <ListGroup.Item>
                                            <Rating value={product.rating}
                                                    text={`${product.numReviews} reviews`}
                                                    color={'#f8e825'}/>
                                        </ListGroup.Item>
                            
                                        <ListGroup.Item>
                                            Price: ${product.price}
                                        </ListGroup.Item>
                            
                                        <ListGroup.Item>
                                            Descriptioin: {product.description}
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Price:</Col>
                                                    <Col>
                                                        <strong>${product.price}</strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Status:</Col>
                                                    <Col>
                                                        {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                
                                            {product.countInStock > 0 && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={'6'}>Qty:</Col>
                                                        <Col xs={'auto'} className={'my-1'}>
                                                            <Form.Select
                                                                value={qty} className="me-sm-3" size="sm"
                                                                onChange={(e) => setQty(e.target.value)}>
                                                                {
                                                                    [
                                                                        ...Array(product.countInStock).keys()
                                                                    ].map((x) => (
                                                                        <option key={x + 1}
                                                                                value={x + 1}>
                                                                            {x + 1}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}
                                
                                            <ListGroup.Item className="text-center">
                                                <Button onClick={addToCartHandler}
                                                        className={'btn-block my-2'} type={'button'}
                                                        hidden={product.countInStock === 0}>
                                                    Add to Cart
                                                </Button>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
    
                            <Row>
                                <Col md={ 6 }>
                                    <h4>Reviews</h4>
                                    { product.reviews.length === 0 &&
                                        <Message variant={ 'info' }>No Reviews</Message> }
                                    <ListGroup variant="flush">
                                        { product.reviews.map((review) => (
                                            <ListGroupItem key={ review._id }>
                                                <strong>{ review.name }</strong>
                                                <Rating value={ review.rating } color={ '#f8e825' }/>
                                                <p>{ review.createdAt.substring(0, 10) }</p>
                                                <p>{ review.comment }</p>
                                            </ListGroupItem>
                                        )) }
                                    
                                        <ListGroupItem>
                                            <h4>Write Review</h4>
                                            { loadingProductReview && <Loader/> }
                                            { successProductReview &&
                                                <Message variant={ 'success' }>Review
                                                    Submitted</Message> }
                                            { errorProductReview &&
                                                <Message
                                                    variant={ 'danger' }>{ errorProductReview }</Message> }
                                    
                                            { userInfo ? (
                                                <Form onSubmit={ submitHandler }>
                                                    <FormGroup controlId="rating">
                                                        <Form.Label>Rating</Form.Label>
                                                        <Form.Control
                                                            as={ 'select' } className="bg-light"
                                                            value={ rating }
                                                            onChange={ (e) => setRating(Number(e.target.value)) }
                                                        >
                                                            <option value="">Select...</option>
                                                            <option value="1">1 - Poor</option>
                                                            <option value="2">2 - Fair</option>
                                                            <option value="3">3 - Good</option>
                                                            <option value="4">4 - Very Good</option>
                                                            <option value="5">5 - Excellent</option>
                                                        </Form.Control>
                                                    </FormGroup>
                                    
                                                    <FormGroup controlId="comment" className="mt-3">
                                                        <Form.Label>Review</Form.Label>
                                                        <Form.Control
                                                            className="bg-light pt-0"
                                                            as={ 'textarea' } rows={ '2' }
                                                            value={ comment }
                                                            onChange={ (e) => setComment(e.target.value) }/>
                                                    </FormGroup>
                                    
                                                    <Button
                                                        disabled={ loadingProductReview }
                                                        type="submit" variant="primary"
                                                        className="mt-3"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form>
                                            ) : (
                                                <Message variant={ 'info' }>
                                                    Please&nbsp;
                                                    <Link to={ '/login' }>&nbsp;Login&nbsp;</Link>
                                                    to write review
                                                </Message>
                                            ) }
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    )
            }

        </div>
    );
}

export default ProductScreen;