import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from "react-bootstrap";
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from "../components/Paginate";
import ProductCarousel from "../components/ProductCarousel";
import {listProducts} from "../actions/productActions";

function HomeScreen() {
    const dispatch = useDispatch();
    const location = useLocation();
    
    const productList = useSelector(state => state.productList);
    const {error, loading, products, page, pages} = productList;
    
    let keyword = location.search
    
    useEffect(() => {
        dispatch(listProducts(keyword))
    }, [dispatch, keyword]);
    
    return (
        <div>
            {!keyword ? <ProductCarousel/>
                : keyword.split('=')[1] === '&page' ? <ProductCarousel/>
                    : <Link to='' className={'btn btn-light my-3'}>Go Home</Link>
            }
            <h1 className="text-center my-3">
                {!keyword || keyword.split('=')[1] === '&page'
                    ? 'Latest Poducts' : 'Serach Results'}
            </h1>
            {loading ? <Loader/>
                : error ? <Message variant={'danger'}>{error}</Message>
                    :
                    <div>
                        <Row>
                            { products.map(p => (
                                <Col key={ p._id } sm={ 12 } md={ 6 } lg={ 4 }>
                                    <Product product={ p }/>
                                </Col>
                            )) }
                        </Row>
                        <Paginate page={ page } pages={ pages } keyword={ keyword }/>
                    </div>
            }
            
        </div>
    );
}

export default HomeScreen;
