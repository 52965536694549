import React, {useEffect} from 'react';
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
    Row, Col, ListGroup, Image, Form, Button, Card, ListGroupItem
} from 'react-bootstrap';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../actions/cartActions';

export default function CartScreen() {
    const {productid} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const qty = location.search ? Number(location.search.split('=')[1]) : 1;
    const dispatch = useDispatch();
    
    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;
    
    useEffect(() => {
        if (productid) {
            dispatch(addToCart(productid, qty))
        }
    }, [dispatch, productid, qty]);
    
    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id));
    };
    
    const checkoutHandler = () => {
        navigate('/login?redirect=/shipping');
    };
    
    return (
        <div>
            <Row>
                <Col md={8}>
                    <h1>Shopping Cart</h1>
                    {cartItems.length === 0 ? (
                        <Message variant={'info'}>
                            Your cart is empty <Link to={'/'}>Go Back</Link>
                        </Message>
                    ) : (
                        <ListGroup variant="flush">
                            {cartItems.map(item => (
                                <ListGroup.Item key={item.product} className="mb-2">
                                    <Row>
                                        <Col md={2}>
                                            <Image src={`/public_html/static${item.image}`} alt={item.name} fluid rounded/>
                                        </Col>
                                        <Col md={3}>
                                            <Link to={`/product/${item.product}`}>{item.name}</Link>
                                        </Col>
                                        <Col md={2}>
                                            {item.price}$
                                        </Col>
                                        <Col md={2}>
                                            <Form.Select
                                                value={item.qty}
                                                onChange={(e) => dispatch(
                                                    addToCart(item.product, Number(e.target.value)))}>
                                                {
                                                    [
                                                        ...Array(item.countInStock).keys()
                                                    ].map((x) => (
                                                        <option key={x + 1} value={x + 1}>
                                                            {x + 1}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col md={1}>
                                            <Button type={'button'} variant="light"
                                                    onClick={() => removeFromCartHandler(item.product)}>
                                                <i className="fas fa-trash"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Col>
    
                <Col md={4}>
                    <Card className="my-md-5">
                        <ListGroup variant="flush">
                            <ListGroupItem>
                                <h2>Subtotal ({
                                    cartItems.reduce((acc, item) => acc + item.qty, 0)
                                }) items</h2>
                    
                                {cartItems.reduce(
                                    (acc, item) => acc + item.qty * item.price, 0).toFixed(2)}$
                            </ListGroupItem>
                            <ListGroupItem className="text-center">
                                <Button type={'button'} className={'btn-block'}
                                        disabled={cartItems.length === 0}
                                        onClick={ checkoutHandler }>
                                    Proceed To Checkout
                                </Button>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
