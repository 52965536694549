import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {Form, Button, FormGroup} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { listProductDetails, updateProduct } from '../actions/productActions';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';

function ProductEditScreen() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [image, setImage] = useState('')
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [uploading, setUploading] = useState(false)
    
    const productDetails = useSelector(state => state.productDetails)
    const { error, loading, product } = productDetails
    
    const productUpdate = useSelector(state => state.productUpdate)
    const {
        error: errorUpdate, loading: loadingUpdate, success: successUpdate
    } = productUpdate
    
    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET })
            navigate('/adminuser/productlist')
        } else {
            if (!product.name || product._id !== Number(id)) {
                dispatch(listProductDetails(id))
            } else {
                setName(product.name)
                setPrice(product.price)
                setImage(product.image)
                setBrand(product.brand)
                setCategory(product.category)
                setCountInStock(product.countInStock)
                setDescription(product.description)
            }
        }
    }, [dispatch, product, id, navigate, successUpdate])
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateProduct({
            _id: id, name, price, image, brand,
            category, countInStock, description
        }))
    };
    
    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        
        formData.append('image', file);
        formData.append('product_id', id);
        
        setUploading(true);
        
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            
            const { data } = await axios.post(
                '/api/products/upload/', formData, config
            )
            
            setImage(data)
            setUploading(false)
            
        } catch (error) {
            setUploading(false)
        }
    }
    
    return (
        <div>
            <Link to={ '/adminuser/productlist' }>
                <Button variant="outline-primary" className="mt-2">
                    Go Back
                </Button>
            </Link>
        
            <FormContainer>
                <h1 className="text-center mb-3">Edit Product</h1>
                { loadingUpdate && <Loader/> }
                { errorUpdate && <Message variant={ 'danger' }>{ errorUpdate }</Message> }
                { loading ? <Loader/> : error
                    ? <Message variant={ 'danger' }>{ error }</Message>
                    : (
                        <Form onSubmit={ submitHandler }>
                            <FormGroup controlId="name" className={ 'mb-3' }>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="name" placeholder='Enter name'
                                    value={ name } onChange={ (e) => setName(e.target.value) }/>
                            </FormGroup>
                        
                            <FormGroup controlId="price" className={ 'mb-3' }>
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    type="number" placeholder='Enter price'
                                    value={ price }
                                    onChange={ (e) => setPrice(Number(e.target.value)) }/>
                            </FormGroup>
                        
                            <FormGroup controlId="image" className={ 'mb-3' }>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type="text" placeholder='Enter image'
                                    value={ image } onChange={ (e) => setImage(e.target.value) }/>
                                <Form.Control
                                    type="file" size="sm"
                                    style={ { cursor: 'pointer'} }
                                    onChange={ uploadFileHandler }/>
                                { uploading && <Loader/> }
                            </FormGroup>
                        
                            <FormGroup controlId="brand" className={ 'mb-3' }>
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    type="text" placeholder='Enter brand'
                                    value={ brand } onChange={ (e) => setBrand(e.target.value) }/>
                            </FormGroup>
                        
                            <FormGroup controlId="countinstock" className={ 'mb-3' }>
                                <Form.Label>Stock</Form.Label>
                                <Form.Control
                                    type="number" placeholder='Enter stock'
                                    value={ countInStock }
                                    onChange={ (e) => setCountInStock(Number(e.target.value)) }/>
                            </FormGroup>
                        
                            <FormGroup controlId="category" className={ 'mb-3' }>
                                <Form.Label>Category</Form.Label>
                                <Form.Control
                                    type="text" placeholder='Enter category'
                                    value={ category } onChange={ (e) => setCategory(e.target.value) }/>
                            </FormGroup>
                        
                            <FormGroup controlId="description" className={ 'mb-3' }>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text" placeholder='Enter description'
                                    value={ description }
                                    onChange={ (e) => setDescription(e.target.value) }/>
                            </FormGroup>
                            <Button
                                type={ 'submit' } variant="primary"
                                className={ 'd-block mx-auto mt-4' }>
                                Update
                            </Button>
                        </Form>
                    ) }
            </FormContainer>
        </div>
    );
}

export default ProductEditScreen;
