import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import SearchBox from "./SearchBox";
import {logout} from "../actions/userActions";

function Header() {
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
    const dispatch = useDispatch();
    
    const logoutHandler = () => {
        dispatch(logout())
    };
    
    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand>Caprice Buyer</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <SearchBox/>
                        <Nav className="justify-content-end" style={{width: "55%"}}>
                            <LinkContainer to="/cart">
                                <Nav.Link><i className={"fas fa-shopping-cart"}/>Cart</Nav.Link>
                            </LinkContainer>
                            
                            {userInfo ? (
                                <NavDropdown title={userInfo.name} id={'username'}>
                                    <LinkContainer to={'/profile'}>
                                        <NavDropdown.Item>Profile</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <LinkContainer to="/login">
                                    <Nav.Link><i className={"fas fa-user"}/>Login</Nav.Link>
                                </LinkContainer>
                            )}
                        
                            {userInfo && userInfo.isadmin && (
                                <NavDropdown title={'Admin'} id={'adminmenue'}>
                                    <LinkContainer to={'/adminuser/userlist'}>
                                        <NavDropdown.Item>Users</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to={'/adminuser/productlist'}>
                                        <NavDropdown.Item>Products</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to={'/adminuser/orderlist'}>
                                        <NavDropdown.Item>Orders</NavDropdown.Item>
                                    </LinkContainer>
                                </NavDropdown>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
