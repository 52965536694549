import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {Form, Button, Col, FormGroup, FormCheck} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'

function PaymentScreen() {
    const navigate = useNavigate();
    
    const cart = useSelector(state => state.cart)
    const {shippingAddress} = cart
    
    const dispatch = useDispatch()
    
    const[paymentMethod, setPaymentMethod] = useState('Pay Method')
    
    if(!shippingAddress.address) {
        navigate('/shipping');
    }
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        navigate('/placeorder');
    }
    return (
        <FormContainer>
            <CheckoutSteps step1 step2 step3/>
            <Form onSubmit={submitHandler}>
                <FormGroup>
                    <Form.Label as={'legend'}>Select Method</Form.Label>
                    <Col>
                        <FormCheck
                            type="radio" label={'Pay Method'}
                            id={'paypal'} name={'paymentMethod'} checked
                            onChange={(e) => setPaymentMethod(e.target.value)}/>
                    </Col>
                </FormGroup>
            
                <Button
                    type={'submit'} variant="primary"
                    className={'mt-3 d-block ml-auto mr-auto'}
                >
                    Continue
                </Button>
            </Form>
        </FormContainer>
    );
}

export default PaymentScreen;
