import React from 'react';
import {Spinner} from "react-bootstrap";

export default function Loader() {
    return (
        <Spinner animation="border" role="status" variant="dark"
                 style={{
                     height:'5em',width:'5em', margin:'auto', display:'block'
                 }}
        >
            <span className="sr-only">Loading...</span>
        </Spinner>
    );
}