import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listUsers, deleteUser } from '../actions/userActions'

function UserListScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const userList = useSelector(state => state.userList)
    const { loading, error, users } = userList
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const userDelete = useSelector(state => state.userDelete)
    const { success: successDelete } = userDelete
    
    useEffect(() => {
        if (userInfo && userInfo.isadmin) {
            dispatch(listUsers())
        } else {
            navigate('/login');
        }
    }, [dispatch,  navigate, successDelete, userInfo])
    
    const deleteHandler = (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            dispatch(deleteUser(id))
        }
    }
    
    return (
        <div>
            <h1 className="text-center mb-2">Users</h1>
            { loading ? (<Loader/>) : error ? (
                <Message variant={ 'danger' }>{ error }</Message>
            ) : (
                <Table striped bordered hover responsive className="table-sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>ADMIN</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    { users.map(user => (
                        <tr key={ user._id }>
                            <td>{ user._id }</td>
                            <td>{ user.name }</td>
                            <td>{ user.email }</td>
                            <td>{ user.isadmin ? (
                                <i className="fas fa-check" style={ {color: "green"} }/>
                            ) : (
                                <i className="fas fa-times" style={ {color: "red"} }/>
                            ) }</td>
                            
                            <td className="text-center" style={ {width: '12rem'} }>
                                <LinkContainer to={ `/adminuser/user/${ user._id }/edit` }>
                                    <Button variant="light" className="btn-sm mx-md-3">
                                        <i className="fas fa-edit"/>
                                    </Button>
                                </LinkContainer>
                                
                                <Button variant="danger" className="btn-sm mx-md-3"
                                        onClick={ () => deleteHandler(user._id) }>
                                    <i className="fas fa-trash"/>
                                </Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            ) }
        </div>
    );
}

export default UserListScreen;
