import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, Button, FormGroup} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import {saveShippingAddress} from "../actions/cartActions";

export default function ShippingScreen() {
    const navigate = useNavigate();
    const cart = useSelector(state => state.cart)
    const {shippingAddress} = cart
    
    const dispatch = useDispatch()
    
    const[address, setAddress] = useState(shippingAddress.address)
    const[city, setCity] = useState(shippingAddress.city)
    const[postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const[country, setCountry] = useState(shippingAddress.country)
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(saveShippingAddress({address, city, postalCode, country}));
        navigate('/payment');
    }
    
    return (
        <div>
            <FormContainer>
                <CheckoutSteps step1 step2/>
                <h1>Shipping</h1>
                <Form onSubmit={submitHandler}>
                    <FormGroup controlId="address" className={'mb-2'}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            required
                            type="text" placeholder='Enter address'
                            value={address ? address : ''}
                            onChange={(e) => setAddress(e.target.value)} />
                    </FormGroup>
                    <FormGroup controlId="city" className={'mb-2'}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            required
                            type="text" placeholder='Enter city'
                            value={city ? city : ''}
                            onChange={(e) => setCity(e.target.value)}/>
                    </FormGroup>
                    <FormGroup controlId="postalCode" className={'mb-2'}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            required
                            type="text" placeholder={'Enter postal code'}
                            value={postalCode ? postalCode : ''}
                            onChange={(e) => setPostalCode(e.target.value)}/>
                    </FormGroup>
                    <FormGroup controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            required
                            type="text" placeholder={'Enter Country'}
                            value={country ? country : ''}
                            onChange={(e) => setCountry(e.target.value)}/>
                    </FormGroup>
                
                    <Button
                        type={'submit'} variant="primary"
                        className={'mt-3 d-block ml-auto mr-auto'}>
                        Continue
                    </Button>
                </Form>
            </FormContainer>
        </div>
    );
}
