import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Form, Button, Row, Col, FormGroup} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import {register} from "../actions/userActions";

export default function RegisterScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    
    const[name, setName] = useState('');
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const[message, setMessage] = useState('');
    const dispatch = useDispatch();
    
    const redirect = location.search ? location.search.split('=')[1] : '/';

    const userRegister = useSelector(state => state.userRegister);
    const {error, loading, userInfo} = userRegister;

    useEffect(() => {
        if(userInfo) {
            navigate(redirect)
        }
    }, [navigate, userInfo, redirect]);
    
    const submitHandler = (e) => {
        e.preventDefault();
        if(password !== confirmPassword) {
            setMessage('Password do not match')
        } else {
            dispatch(register(name, email, password))
        }
    };
    
    return (
        <FormContainer>
            <h1>Sign In</h1>
            {message && <Message variant={'danger'}>{message}</Message>}
            {error && <Message variant={'danger'}>{error}</Message>}
            {loading && <Loader/>}
            <Form onSubmit={submitHandler}>
                <FormGroup controlId="name" className={'mb-3'}>
                    <Form.Label className="mb-0">Name</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        required
                        type="name" placeholder='Enter name'
                        value={name} onChange={(e) => setName(e.target.value)}/>
                </FormGroup>
                <FormGroup controlId="email" className={'mb-3'}>
                    <Form.Label className="mb-0">Email Address</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        required
                        type="email" placeholder='Enter Email'
                        value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormGroup>
                <FormGroup controlId="password" className={'mb-3'}>
                    <Form.Label className="mb-0">Password</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        required
                        type="password" placeholder={'Enter Password'}
                        value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormGroup>
                <FormGroup controlId="passwordConfirm">
                    <Form.Label className="mb-0">Confirm Password</Form.Label>
                    <Form.Control
                        style={{backgroundColor: "ghostwhite"}}
                        required
                        type="password" placeholder={'Confirm Password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}/>
                </FormGroup>
            
                <Button
                    type={'submit'} variant="primary"
                    className={'mt-3 d-block ml-auto mr-auto'}>
                    Register
                </Button>
            </Form>
        
            <Row className={'py-3'}>
                <Col>
                    Have an Account?&nbsp;
                    <Link
                        className={'reglink'}
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}
                    >
                        Sign In
                    </Link>
                </Col>
            </Row>
        </FormContainer>
    );
}
