import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Form, Button, Row, Col, Table, FormGroup} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Loader from '../components/Loader';
import Message from '../components/Message';
import {getUserDetails, updateUserProfile} from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import {listMyOrders} from "../actions/orderActions";

export default function ProfileScreen() {
    const navigate = useNavigate();
    
    const[name, setName] = useState('')
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const[confirmPassword, setConfirmPassword] = useState('')
    const[message, setMessage] = useState('')
    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile
    
    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy
    
    useEffect(() => {
        if(!userInfo) {
            navigate('/login')
        } else {
            if(!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({type: USER_UPDATE_PROFILE_RESET})
                dispatch(getUserDetails('profile'))
                dispatch(listMyOrders())
            } else {
                setName(user.name)
                setEmail(user.email)
            }
        }
    }, [dispatch, navigate, userInfo, user, success])
    
    const submitHandler = (e) => {
        e.preventDefault();
        if(password !== confirmPassword) {
            setMessage('Password do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password
            }))
            setMessage('')
        }
    }
    
    return (
        <Row>
            <Col md={3} className="mx-3">
                <h2 className="text-center mb-3">User Profile</h2>
                {message && <Message variant={'danger'}>{message}</Message>}
                {error && <Message variant={'danger'}>{error}</Message>}
                {loading && <Loader/>}
                <Form onSubmit={submitHandler}>
                    <FormGroup controlId="name" className={'mb-2'}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            type="name" placeholder='Enter name'
                            value={name} onChange={(e) => setName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup controlId="email" className={'mb-2'}>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type="email" placeholder='Enter Email'
                            value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </FormGroup>
                    <FormGroup controlId="password" className={'mb-2'}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password" placeholder={'Enter Password'}
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </FormGroup>
                    <FormGroup controlId="passwordConfirm">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password" placeholder={'Confirm Password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </FormGroup>
                
                    <Button
                        type={'submit'} variant="primary"
                        className={'mt-4 d-block mx-auto'}>
                        Update
                    </Button>
                </Form>
            </Col>
    
            <Col md={ 8 }>
                <h2 className="text-center mb-3">My Orders</h2>
                { loadingOrders ? (
                    <Loader/>
                ) : errorOrders ? (
                    <Message variant={ 'danger' }>{ errorOrders }</Message>
                ) : (
                    <Table striped responsive className="table-sm">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Total</th>
                            <th>Paid</th>
                            <th>Delivered</th>
                            <th/>
                        </tr>
                        </thead>
                
                        <tbody>
                        { orders.map(order => (
                            <tr key={ order._id }>
                                <td>{ order._id }</td>
                                <td>{ order.createdAt.substring(0, 10) }</td>
                                <td>{ order.totalPrice }</td>
                                <td>{ order.isPaid ? order.paidAt.substring(0, 10) : (
                                    <i className="fas fa-times" style={{color: "red"}}/>
                                ) }</td>
                        
                                <td>
                                    <LinkContainer to={ `/order/${ order._id }` }>
                                        <Button className="btn-sm">Details</Button>
                                    </LinkContainer>
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </Table>
                ) }
            </Col>
        </Row>
    );
}
