import React, {useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {Button, Form, FormControl} from "react-bootstrap";

function SearchBox() {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [keyword, setKeyword] = useState('');
    
    const submitHandler = (e) => {
        e.preventDefault()
        if(keyword) {
            navigate(`/?keyword=${keyword}&page=1`)
        } else {
            navigate(navigate(location.pathname))
        }
    }
    
    return (
        <Form onSubmit={ submitHandler } inline="true" className="d-flex mx-3">
            <FormControl
                type="text" name={ 'q' } size="sm"
                style={{minWidth: 'fit-content'}}
                onChange={ (e) => setKeyword(e.target.value) }
                className="mx-sm-1 bg-gradient px-1"/>
            <Button type="submit" variant="outline-success"
                    size="sm" style={{minWidth: 'fit-content'}}>
                <i className="fa fa-search" style={{marginRight: "0.3em"}}/>
                Search
            </Button>
        </Form>
    );
}

export default SearchBox;
