import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {Carousel, CarouselItem, Image} from 'react-bootstrap';
import Loader from './Loader';
import Message from './Message';
import { listTopProducts } from '../actions/productActions';

function ProductCarousel() {
    const dispatch = useDispatch()
    
    const productTopRated = useSelector(state => state.productTopRated)
    const {error, loading, products} = productTopRated
    
    useEffect(() => {
        dispatch(listTopProducts())
    }, [dispatch])
    
    return (loading ? <Loader/>
            : error
                ? <Message variant='danger'>{ error }</Message>
                : (
                    <Carousel pause={ 'hover' } className="bg-dark bg-opacity-50 bg-gradient">
                        { products.map(product => (
                            <CarouselItem key={ product._id }>
                                <Link to={ `/product/${ product._id }` }>
                                    <Image src={ `/public_html/static${product.image}` } alt={ product.name } fluid/>
                                    <Carousel.Caption className='carousel.caption'>
                                        <h4 className="text-light">
                                            { product.name } (${ product.price })
                                        </h4>
                                    </Carousel.Caption>
                                </Link>
                            </CarouselItem>
                        )) }
                    </Carousel>
                )
    );
}

export default ProductCarousel;
